<script>
import { ref } from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required, helpers } from "@vuelidate/validators";
import { reqMethods } from '../../../state/helpers';
import AudioPlayer from 'vue3-audio-player';
import 'vue3-audio-player/dist/style.css';
import axios from 'axios';
import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
/**
 * Email-read component
 */
export default {
  setup() {
    const isOpen4 = ref(false);
    return {
      isOpen4,
    };
  },
  components: {
    Layout,
    PageHeader,
    AudioPlayer,
    Multiselect,
    VueFileAgent,
  },
  data() {
    return {
      center: {
        lat: null,
        lng: null
      },
      title: "Détails de l'alerte",
      zoom: 14,
      maxZoom: 25,
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      items: [
        {
          text: "Alertes",
          href: "/alert/list-alert"
        },
        {
          text: "Détails",
          active: true
        }
      ],
      MarkerUpdate: [],
      audiosLocal: [],
      audios: [],
      images: [],
      videos: [],
      pposition: {},
      files: [],
      spanView: false,
      isRecording: false,
      mediaRecorder: null,
      chunks: [],
      audioURL: null,
      pertinence: null,
      alert: {},
      created_at: '',
      fildsState: true,
      data: {
        send: null,
        thematic: null,
        title: null,
        urgency_level: null,
        description: null,
        cover_areas: null,
        photos: [],
        videos: [],
        audios: [],
        files: [],
        audiosLocal: [],
      },
      send: true,
      Markers: [],
      markers: [],
      options: [],
      zones: [],
      niveaux: [
        { value: '0', label: 'Normal' },
        { value: '1', label: 'Urgent' },
      ],
      position: { lat: 6.505, lng: 1.329 },
      reqError: null,
      reqResponse: null,
      submitted: false,
      form: {
        message: "",
      },
      username: "Steven Franklin",
    };
  },
  created() {
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    update() {
      this.fildsState = false;
    },
    getAlertDetails() {
      const that = this;
      const id = { id: parseInt(this.$route.params.id) };
      this.postRequest({
        route: '/alert/findbyid/',
        data: id,
      }).then(
        function (response) {
          that.alert = response.data;
          that.data.send = response.data.alerts[0]?.send;
          that.data.thematic = response.data.alerts[0]?.thematic?.id;
          that.data.title = response.data.alerts[0]?.title;
          that.data.urgency_level = response.data.alerts[0]?.urgency_level;
          that.data.description = response.data.alerts[0]?.description;
          that.created_at = response.data.alerts[0]?.created_at;
          that.data.cover_areas = [];
          response.data?.cover_areas?.forEach(element => {
            that.data.cover_areas.push(element?.cover_area.id)
          });
        },
        function (error) {
          that.reqError = error;
        }
      )
    },
    deleteThisAlert() {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette alerte? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          const formData = new FormData();
          formData.append('id', parseInt(this.$route.params.id));
          formData.append('is_active', false);
          try {
            const response = await axios.put('https://api.alcit.sims-technologie.com/api/v1/alert/alert/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token'),
              },
            });
            Swal.fire(response.data.message, "L'Alerte à été Supprimer!", "success");
            this.$router.push('/listAlert');
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de l'ajout de l'alerte",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },

    async publishAlerte() {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous publier cette alerte? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Publier!"
      }).then(async result => {
        if (result.value) {
          const formData = new FormData();
          formData.append('id', parseInt(this.$route.params.id));
          formData.append('send', true);
          try {
            const response = await axios.put('https://api.alcit.sims-technologie.com/api/v1/alert/alert/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token'),
              },
            });
            Swal.fire(response.data.message, "L'Alerte à été Publier!", "success");
            location.reload();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la publication de l'alerte",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })

    },
    setPertinence(pertinence) {
      this.pertinence = pertinence;
    },
    showMarkerOnMap(index) {
      const that = this;
      const marker = this.alert?.coordinates[index];
      this.Markers.push(marker);
      this.center = marker.coordinate?.coordinates;
      this.zoom = 13;
      const newPosition = {
        position: {
          lat: marker.coordinate.coordinates[0],
          lng: marker.coordinate.coordinates[1],
          title: `Position ${index + 1}`,
          id: marker.id
        }
      }
      console.log(newPosition);
      that.markers = [...that.markers, newPosition];
    },
    openPDF(url) {
      window.open(url, '_blank');
    },
    removeAudio(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cet audio? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/alert/alert/audio/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "L'Audio à été supprimer!", "success");
            this.getAlertDetails();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression de l'audio",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    removePhoto(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette image? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/alert/alert/image/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "L'image à été supprimer!", "success");
            this.getAlertDetails();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression de l'image",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    removeVideo(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette vidéo? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/alert/alert/video/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "La vidéo à été supprimer!", "success");
            this.getAlertDetails();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression de la video",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    removeFile(id) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer ce fichier? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/alert/alert/file/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "le fichier à été supprimer!", "success");
            this.getAlertDetails();
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression du fichier",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    deleteMarker(id) {
      console.log(id)
      if(this.data.send === true)
        return;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette position? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "supprimer!"
      }).then(async result => {
        if (result.value) {
          try {
            const response = await axios.delete(`https://api.alcit.sims-technologie.com/api/v1/alert/alert/coordinate/?id=${id}`, {
              headers: {
                'Authorization': localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
              },
            })
            Swal.fire(response.data.message, "Le point à été supprimer!", "success");
            this.getAlertDetails();
            const markerIndex = this.Markers.findIndex(m => m.id === id);
            if (markerIndex !== -1) {
              this.Markers.splice(markerIndex, 1);
            }
          } catch (error) {
            console.log(error);
            Swal.fire({
              title: "Oops...",
              text: "Erreur lors de la suppression du point",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          }
        }
      })
    },
    addMarker(event) {
      event && event.lat && this.MarkerUpdate.push([event.latLng.lat(), event.latLng.lng()]);
      if(this.data.send === true)
        return;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez-vous ajouter de nouvelles coordonées?",
        position: "top",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Ajouter!"
      }).then(async result => {
        if (result.value) {
          if (event && event.latlng) {
            const { lat, lng } = event.latlng;
            const coordinate = `POINT(${lat} ${lng})`;
            const formData = new FormData();
            formData.append('alert', parseInt(this.$route.params.id));
            formData.append('coordinate', coordinate);
            try {
              const response = await axios.post(`https://api.alcit.sims-technologie.com/api/v1/alert/alert/coordinate/`, formData, {
                headers: {
                  'Authorization': localStorage.getItem('token'),
                  'Content-Type': 'multipart/form-data',
                },
              })
              Swal.fire(response.data.message, "La position a été ajouter!", "success");
              this.getAlertDetails();
              this.MarkerUpdate = [];
            } catch (error) {
              console.log(error);
              Swal.fire({
                title: "Oops...",
                text: "Erreur lors de la suppression de la video",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        } else {
          this.MarkerUpdate = [];
        }
      })

    },
    getThematics() {
      const that = this;
      this.getRequest('/alert/thematic/center/')
        .then(
          function (response) {
            response.data.forEach(element => {
              that.options.push({ value: element.id, label: element.name });
            });
          },
          function (error) {
            that.fetchError = error;
          }
        )
    },
    getZones() {
      const that = this;
      this.getRequest('/account/zone/')
        .then(
          function (response) {
            response.data.forEach(element => {
              that.zones.push({ value: element.id, label: element.name });
            });
          },
          function (error) {
            that.fetchError = error;
          }
        )
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.addEventListener('dataavailable', this.onDataAvailable);
        this.mediaRecorder.addEventListener('stop', this.onRecordingStopped);
        this.chunks = [];
        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error('Erreur lors de la demande d\'accès à l\'audio:', error);
      }
    },

    async convertBlobToAudioFile(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      const currentDate = new Date();
      const fileName = currentDate.toLocaleString('fr-FR', { timeZone: 'UTC' })
        .replace(/[/:]/g, '-') + '.wav';

      const file = new File([blob], fileName, { type: 'audio/wav' });
      return file;
    },

    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },

    onDataAvailable(event) {
      event.data.size > 0 ? this.chunks.push(event.data) : null;
    },

    async onRecordingStopped() {
      const audioBlob = new Blob(this.chunks, { type: 'audio/wav' });
      const audioURL = URL.createObjectURL(audioBlob);
      this.audiosLocal.push(audioURL);
      const audioFile = await this.convertBlobToAudioFile(audioURL);
      this.audios.push(audioFile);
    },
    async addImg() {
      const formData = new FormData();
      formData.append('alert', parseInt(this.$route.params.id));
      for (const photo of this.images) { formData.append('image', photo.file); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/alert/alert/image/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
        this.getAlertDetails();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async addAudio() {
      const formData = new FormData();
      formData.append('alert', parseInt(this.$route.params.id));
      for (const audio of this.audios) { formData.append('audio', audio); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/alert/alert/audio/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });
        this.getAlertDetails();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async addVideo() {
      const formData = new FormData();
      formData.append('alert', parseInt(this.$route.params.id));
      for (const video of this.videos) { formData.append('video', video.file); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/alert/alert/video/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });

        this.getAlertDetails();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async addFile() {
      const formData = new FormData();
      formData.append('alert', parseInt(this.$route.params.id));
      for (const file of this.files) { formData.append('file', file.file); }
      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/alert/alert/file/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });

        this.getAlertDetails();
        Swal.fire(response.data.message, "ajout réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async updateCoverAreas() {
      const formData = new FormData();
      formData.append('alert', parseInt(this.$route.params.id));
      formData.append('cover_area', this.data.cover_areas);

      try {
        const response = await axios.post("https://api.alcit.sims-technologie.com/api/v1/alert/alert/coverarea/", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': localStorage.getItem('token'),
            },
          });

        this.getAlertDetails();
        Swal.fire(response.data.message, "modification réussi!", "success");
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de la modification",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async updateAlert() {
      const formData = new FormData();
      formData.append('id', parseInt(this.$route.params.id));
      formData.append('thematic', this.data.thematic);
      formData.append('title', this.data.title);
      formData.append('urgency_level', this.data.urgency_level);
      formData.append('cover_areas', this.data.cover_areas);
      formData.append('description', this.data.description);
      const that = this;

      try {
        const response = await axios.put('https://api.alcit.sims-technologie.com/api/v1/alert/alert/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        Swal.fire(response.data.message, "L'Alerte à été Modifier!", "success");
        this.getAlertDetails();
        that.spanView = false;
      } catch (error) {
        that.spanView = false;
        that.reqError = error;
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout de l'alerte",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    deleteAudio(index) {
      this.audiosLocal.splice(index, 1);
      this.audios.splice(index, 1);
    },
  },
  mounted() {
    this.getThematics();
    this.getAlertDetails();
    this.getZones();
    const that = this;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }, function (error) {
        alert("Erreur de géolocalisation : " + error.message);
      });
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="row justify-content-between">
            <div class="col-md-7 d-flex p-3 mt-2">
              <h3 class="text-primary me-2 font-size-18">STATUT:</h3>
              <h3 class="font-size-18 text-danger" v-if="data.send == false">Non Publiée</h3>
              <h3 class="font-size-18 text-success" v-if="data.send == true">Publiée</h3>
              <i class="mdi mdi-calendar ms-3 font-size-18"></i>
              <h3>{{ created_at?.split('T')[0] }}</h3>
              <i class="mdi mdi-clock ms-3 font-size-18"></i>
              <h3>{{ created_at?.split('T')[1]?.split('.')[0] }}</h3>
            </div>
            <div class="col-md-5">
              <div class="btn-toolbar justify-content-end p-3" role="toolbar">
                <button v-if="data.send == false" type="button" @click="publishAlerte()" class="btn btn-success me-2">
                  <i class="mdi mdi-send"></i>
                  Publier
                </button>
                <button v-if="data.send == false" type="button" @click="update()" class="btn btn-primary me-2">
                  <i class="mdi mdi-pencil"></i>
                  Modifier
                </button>
                <button type="button" @click="deleteThisAlert()" class="btn btn-danger me-2">
                  <i class="mdi mdi-trash-can"></i>
                  Désactiver
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Thématique:</strong>
              </div>
              <div class="col-md-10">
                <multiselect class="test-primary" v-model="data.thematic" :disabled="fildsState" :options="options">
                </multiselect>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Titre:</strong>
              </div>
              <div class="col-md-10">
                <input type="text" ref="input" class="form-control" v-model="data.title" :disabled="fildsState">
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-2">
                <strong class="font-size-13 text-primary">Niveau d'urgence:</strong>
              </div>
              <div class="col-md-10">
                <multiselect class="test-primary" v-model="data.urgency_level" :disabled="fildsState" :options="niveaux">
                </multiselect>
              </div>
            </div>
            <p></p>
              <!-- <div class="form-group col-md-6 mb-3">
                <label for="title" class="col-form-label font-size-13 text-primary">Thématique:</label>
                <multiselect class="test-primary" v-model="data.thematic" :disabled="fildsState" :options="options">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mb-3">
                <label for="title" class="col-form-label font-size-13 text-primary">Titre:</label>
                <input type="text" ref="input" class="form-control" v-model="data.title" :disabled="fildsState">
              </div>
              <div class="form-group col-md-12 mb-3">
                <label for="title" class="col-form-label font-size-13 text-primary">Niveau d'urgence:</label>
                <multiselect class="test-primary" v-model="data.urgency_level" :disabled="fildsState" :options="niveaux">
                </multiselect>
              </div> -->
              <b-card no-body class="mb-1">
  <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
    <h6 class="m-0">
      <a class="text-dark" href="javascript: void(0);"><strong>Messages </strong></a>
    </h6>
  </b-card-header>
  <b-card-body>
    <div
      id="projectdesc"
      ref="tf"
      v-html="data.description"
      class="form-control"
      readonly
      placeholder="Donnez une description détaillée de l'alerte..."
    ></div>
  </b-card-body>
</b-card>

              <div class="row" v-if="!fildsState">
                <div class="col-md-12 d-flex justify-content-center">
                  <button type="button" @click="updateAlert()" title="Ne pas diffuser maintenant" class="btn btn-primary"
                    :disabled="spanView">
                    Modifier l'alerte
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">

              <b-card no-body class="mb-1" v-if="data.send == true">
                <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Zones de couverture </strong></a>
                  </h6>
                </b-card-header>
                  <b-card-body>  
                    <multiselect class="test-primary" v-model="data.cover_areas" :options="zones" :disabled="true"
                  mode="tags">
                </multiselect>
                  </b-card-body>
              </b-card>
              <div class="form-group col-md-12 mb-3" v-if="data.send == false">
                <label for="title" class="col-form-label font-size-13 text-primary">Zones de couverture:</label>
                <div class="col-md-12 d-flex justify-content-between">
                  <multiselect class="test-primary" v-model="data.cover_areas" :options="zones" mode="tags">
                  </multiselect>
                  <button type="button" @click="updateCoverAreas()" class="btn btn-primary ms-3" :disabled="spanView">
                    Valider
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-3">
              <div class="my-4 text-center">
                <b-modal v-model="isOpen4" id="modal-center" centered title="Enregistrer audio" title-class="font-18"
                  hide-footer>
                  <div class="form-group row mb-4">
                    <div class="col-lg-12" style="border:1px solid gray; border-radius: 30px">
                      <div class="audio-recorder">
                        <div class="recorder-controls">
                          <button type="button" @click="startRecording" :disabled="isRecording"
                            :class="{ 'record-button': true, 'pulse': isRecording }">
                            <span v-if="!isRecording"><i class="mdi mdi-microphone-outline"></i></span>
                            <span v-else><i class="mdi mdi-microphone-settings"></i></span>
                          </button>
                          <button type="button" @click="stopRecording" :disabled="!isRecording" class="stop-button"><i
                              class="mdi mdi-stop"></i>
                          </button>
                        </div>
                        <div class="audio-player mt-2" v-if="audiosLocal.length > 0">
                          <div class="audio-item" v-for="(audio, index) in audiosLocal" :key="index">
                            <audio :src="audio" controls></audio>
                            <button type="button" @click="deleteAudio(index)" class="delete-button">
                              <i class="mdi mdi-close"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-end mt-2">
                      <div class="col-md-2">
                        <button type="button" @click="addAudio()" title="Ne pas diffuser maintenant"
                          class="btn btn-primary" :disabled="spanView">
                          Ajouter
                          <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
            <div v-if="alert.audios === undefined && data.send == false">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-5 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Audios </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6">
                        <div class="d-flex" @click="isOpen4 = true"
                          style="height: 70px; width: 70px; border: 1px solid gray; border-radius:10%; cursor: pointer">
                          <i class="mdi mdi-plus font-size-22 m-auto"></i>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert?.audios?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-4 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Audios </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="audio in alert.audios" :key="audio.id">
                        <div class="card">
                          <i @click="removeAudio(audio.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            v-if="data.send == false"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <AudioPlayer :option="{
                            src: `https://api.alcit.sims-technologie.com${audio.audio}`,
                            title: `Audio-${audio.id}.wav`,
                            coverImage: `https://api.alcit.sims-technologie.com/media/frontutils/sound.png`,
                          }" />
                        </div>
                      </div>
                      <div class="col-xl-2 col-6" v-if="data.send == false">
                        <div class="d-flex" @click="isOpen4 = true"
                          style="height: 100%; width: 100%; border: 1px solid gray; border-radius:10%; cursor: pointer">
                          <i class="mdi mdi-plus font-size-22 m-auto"></i>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert.photos === undefined && data.send == false">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-6 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Images </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-3 col-6 mt-3">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'image/*'" :uploadUrl="uploadUrl" v-model="images" />
                        <button @click="addImg()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert?.photos?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);">
                      <strong>Images </strong>
                    </a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row" v-viewer>
                      <div class="col-xl-2 col-6" v-for="photo in alert.photos" :key="photo.id">
                        <div class="card">
                          <i @click="removePhoto(photo.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            v-if="data.send == false"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <img class="card-img-top img-fluid"
                            :src="`https://api.alcit.sims-technologie.com${photo.image}`" alt="Card image cap" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-6 mt-3" v-if="data.send == false">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'image/*'" :uploadUrl="uploadUrl" v-model="images" />
                        <button @click="addImg()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert.videos === undefined && data.send == false">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-7 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Videos </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-3 col-6 mt-3">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'video/*'" :uploadUrl="uploadUrl" v-model="videos" />
                        <button @click="addVideo()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert?.videos?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-3 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Videos</strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="src in alert.videos" :key="src.id">
                        <div class="card">
                          <i @click="removeVideo(src.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            v-if="data.send == false"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <video class="card-img-top img-fluid"
                            :src="`https://api.alcit.sims-technologie.com${src.video}`" controls alt="Card image cap" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-6 mt-3" v-if="data.send == false">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'video/*'" :uploadUrl="uploadUrl" v-model="videos" />
                        <button @click="addVideo()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert.files === undefined && data.send == false">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-8 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Fichers </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-3 col-6 mt-3">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'.pdf'" :uploadUrl="uploadUrl" v-model="files" />
                        <button @click="addFile()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <div v-if="alert?.files?.length > 0">
              <b-card no-body class="mb-1">
                <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a class="text-dark" href="javascript: void(0);"><strong>Fichers </strong></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-xl-2 col-6" v-for="file in alert.files" :key="file.id">
                        <div class="card text-center">
                          <i @click="removeFile(file.id)"
                            class="mdi mdi-close m-auto font-size-20 position-relative bg-danger"
                            v-if="data.send == false"
                            style="cursor: pointer;top:33px; font-weight: 900; left: 40%; color:white;font-size: 18px; border-radius:5px;"></i>
                          <a @click="openPDF(`https://api.alcit.sims-technologie.com${file.file}`)" href="#">
                            <img class="card-img-top img-fluid" src="@/assets/images/pdf.png" alt="Card image cap" />
                          </a>
                          <strong class="font-size-13 mt-2">File-{{ file.file.split('/media/')[1] }}</strong>
                        </div>
                      </div>
                      <div class="col-xl-3 col-6 mt-3" v-if="data.send == false">
                        <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                          :accept="'.pdf'" :uploadUrl="uploadUrl" v-model="files" />
                        <button @click="addFile()" class="btn btn-info mt-1">Ajouter</button>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <hr />
          <div class="card-body">
            <div>
              <div class="row mt-3 justify-content-between" v-if="alert?.coordinates?.length > 0">
                <div class="col-md-2">
                  <strong class="font-size-14 text-primary">Positions:</strong>
                </div>
                <div class="col-md-10">
                  <ul>
                    <li v-for="(marker, index) in alert.coordinates" :key="index">
                      <a style="cursor: pointer;" @click="showMarkerOnMap(index)">position {{ index + 1 }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-lg-12">

                  <GMapMap
                      :center=center
                      :zoom=10
                      ref="myMapRef"
                      map-type-id="terrain"
                      @click="addMarker"
                      style="width: 100%; height: 900px"
                  >
                    <GMapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        @click="deleteMarker(m.position.id)"
                        :icon='{
          url: "/maps/position.svg",
          scaledSize: {width: 62, height: 62},
          labelOrigin: {x: 16, y: -10}
      }'
                    >
                      <GMapInfoWindow
                          :opened="true"
                      >
                        <div>{{ m.position.title }}
                          <MyComponent/>
                        </div>
                      </GMapInfoWindow>
                    </GMapMarker>
                  </GMapMap>


<!--                  <l-map :zoom="zoom" style="height: 500px;" :center="center" @click="addMarker">
                    <l-tile-layer :url="url" :maxZoom="maxZoom" />
                    <l-marker v-for="(marker, index) in Markers" :key="index" @click="deleteMarker(marker)"
                      :visible="true" :lat-lng="marker.coordinate?.coordinates">
                      <l-icon :icon-anchor="staticAnchor">
                        <img src="http://api.alcit.sims-technologie.com/media/frontutils/marker-icon.png" />
                      </l-icon>
                    </l-marker>
                    <l-marker v-for="(marker, index) in MarkerUpdate" :key="index" :visible="true" :lat-lng="marker">
                      <l-icon :icon-anchor="staticAnchor">
                        <img src="http://api.alcit.sims-technologie.com/media/frontutils/marker0.png" />
                      </l-icon>
                    </l-marker>
                  </l-map>-->
                </div>
              </div>
<!--              <div class="form-group row mt-3" v-if="data.send == true">
                <div class="col-lg-12">
                  <l-map :zoom="zoom" style="height: 500px;" :center="center">
                    <l-tile-layer :url="url" :maxZoom="maxZoom" />
                    <l-marker v-for="(marker, index) in Markers" :key="index" :visible="true"
                      :lat-lng="marker.coordinate?.coordinates">
                      <l-icon :icon-anchor="staticAnchor">
                        <img src="http://api.alcit.sims-technologie.com/media/frontutils/marker-icon.png" />
                      </l-icon>
                    </l-marker>
                  </l-map>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.recorder-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  transition: opacity .3s, transform .3s;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index: 1;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.record-button {
  background-color: #28a745;
  position: relative;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: -50;
}

.stop-button {
  background-color: #dc3545;
  position: relative;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
}

.audio-player {
  width: 100%;
}

.audio-item {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
  